import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import AugmentTooltip from "@/game-tft/AugmentTooltip.jsx";
import {
  isFutureSet,
  isRevivalSet,
  STATS_PLACEHOLDER_SET,
} from "@/game-tft/constants.mjs";
import getFilters from "@/game-tft/get-stats-filters.mjs";
import { cssTierIcon } from "@/game-tft/OverviewChampions.style.jsx";
import {
  Search,
  SelectGameMode,
  SelectRank,
  SelectRegion,
} from "@/game-tft/Selects.jsx";
import SetTile from "@/game-tft/SetTile.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import StatsTooltip from "@/game-tft/StatsTooltip.jsx";
import { useLocalizedAugments } from "@/game-tft/use-augments.mjs";
import { useLegendsByParams } from "@/game-tft/use-legends.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import DataTable from "@/shared/DataTable.jsx";
import getTierIcon from "@/shared/get-tier-icon.mjs";
import { FlexContainer } from "@/shared/InfiniteTable.style.jsx";
import clone from "@/util/clone.mjs";
import { formatNumberWithUnit, getLocale } from "@/util/i18n-helper.mjs";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const columns = [
  {
    label: ["common:navigation.legend", "Legend"],
    dataKey: "name",
    align: "left",
    primary: true,
  },
  {
    label: ["tft:tier", "Tier"],
    dataKey: "tier",
  },
  {
    label: ["tft:pickRate", "Pick Rate"],
    // labelSub: ["tft:table.caption.total", "{{count}} Games", { count: 0 }],
    dataKey: "pick_rate",
    align: "right",
    isStat: true,
  },
  {
    label: ["tft:stage", "Stage"],
    dataKey: "stage",
    align: "right",
  },
];

function StatsLegends() {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const [tableCol] = useState(clone(columns));
  const isLoaded = useIsLoaded();
  const set = useSetByParam(true);
  const state = useSnapshot(readState);
  const legends = state.tft[set]?.legends?.[set];
  const augments = state.tft[set]?.augments;
  const legendsFilters = getFilters(
    searchParams,
    "legends",
    isFutureSet(set),
    isRevivalSet(set),
  );
  const legendsStats = state.tft.stats.legends?.[legendsFilters]?.[set];
  const typeParam = searchParams.get("type") ?? "ALL";
  const search = searchParams.get("search");
  const locale = getLocale();
  const localizedAugments = useLocalizedAugments(set);
  const items = useLegendsByParams({
    legends,
    legendsStats,
    augments,
    localizedAugments,
    set,
    type: typeParam,
  }).sort((a, b) => a.tier - a.pick_rate - (b.tier - b.pick_rate));

  if (!items?.length && set === STATS_PLACEHOLDER_SET)
    return <SetTile page="Statistics" />;

  // add augments to each legend
  const legendRows = [];
  for (const legend of items) {
    legendRows.push(legend);
    legend.augments?.forEach((augment) => {
      legendRows.push(augment);
    });
  }

  let filteredRows = legendRows;
  if (typeParam === "LEGEND") {
    filteredRows = filteredRows.filter((i) => i.type === "legend");
  }

  if (typeParam === "AUGMENT") {
    filteredRows = filteredRows.filter((i) => i.type === "augment");
  }

  // search filter
  if (search?.length > 1) {
    const instance = RegExp(search, "i");
    filteredRows = filteredRows.filter(({ name, objKey }) =>
      instance.test(name ?? objKey),
    );
  }

  // Renderers
  function colRenderer({ dataKey, rowData }) {
    if (dataKey === "rank") {
      return (
        <div className="type-body2">
          {typeParam !== "AUGMENT"
            ? rowData.type === "legend" &&
              items.indexOf(items.find((i) => i.objKey === rowData.objKey)) + 1
            : filteredRows.indexOf(
                filteredRows.find((i) => i.objKey === rowData.objKey),
              ) + 1}
        </div>
      );
    }
    if (dataKey === "name") {
      const Name = ({ objKey, name, type, showName }) => (
        <NameContainer className="type-body2">
          {type === "legend" ? (
            <>
              <img
                src={`${appURLs.CDN}/blitz/tft/legends/${set}/${objKey
                  ?.replace(/\s/g, "")
                  .toLowerCase()}.webp`}
                alt={name}
                width={32}
                height={32}
              />
              {showName &&
                name +
                  (objKey?.includes("_Legend_")
                    ? ` - ${t("tft:legend", "Legend")}`
                    : "")}
            </>
          ) : (
            <AugmentTooltip
              augment={objKey}
              className={"flex"}
              style={{
                marginLeft: typeParam !== "AUGMENT" ? "var(--sp-4)" : "0",
                alignItems: "center",
                gap: "var(--sp-1)",
              }}
            >
              <img
                src={StaticTFT.getAugmentImage(objKey, set)}
                alt={name}
                width={32}
                height={32}
              />
              {showName &&
                name +
                  (objKey?.includes("_Legend_")
                    ? ` - ${t("tft:legend", "Legend")}`
                    : "")}
            </AugmentTooltip>
          )}
        </NameContainer>
      );
      return (
        <Name
          champKey={rowData.champKey}
          cost={rowData.cost}
          name={rowData.name}
          objKey={rowData.objKey}
          type={rowData.type}
          showName={true}
        />
      );
    }
    if (dataKey === "tier") {
      const TierIcon = getTierIcon(rowData.tier);
      return TierIcon ? <TierIcon className={cssTierIcon()} /> : null;
    }
    if (dataKey === "pick_rate" && typeof rowData.pick_rate === "number") {
      const digits = 1;
      return t("lol:percent", "{{number}}%", {
        number: (rowData.pick_rate * 100).toLocaleString(locale, {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
        }),
      });
    }
    if (dataKey === "stage") {
      return <div className="type-body2">{rowData.stage || "-"}</div>;
    }

    return null;
  }

  const parseStringValue = (value) => {
    if (typeof value === "string") {
      return parseFloat(value);
    }
    return value;
  };

  if (!Array.isArray(filteredRows) || !filteredRows?.length) return "";

  const cols = tableCol.map((col) => {
    return {
      display: col.labelSub ? (
        <StatsTooltip
          statKey={
            col.dataKey === "pick_rate"
              ? typeParam === "AUGMENT"
                ? "pick_rate_legend_augments"
                : "pick_rate_legends"
              : col.dataKey
          }
        >
          <div className="flex column align-center gap-1">
            <div>{t(col.label[0], col.label[1])}</div>
            <div className="shade3">
              {t(col.labelSub[0], col.labelSub[1], col.labelSub[2])}
            </div>
          </div>
        </StatsTooltip>
      ) : (
        <StatsTooltip
          statKey={
            col.dataKey === "pick_rate"
              ? typeParam === "AUGMENT"
                ? "pick_rate_legend_augments"
                : "pick_rate_legends"
              : col.dataKey
          }
        >
          {t(col.label[0], col.label[1])}
        </StatsTooltip>
      ),
      align: col.align,
      primary: col.primary,
      isStat: col.isStat,
    };
  });
  const rows = filteredRows.map((item, i) => {
    return tableCol.map((col) => {
      return {
        display: colRenderer({
          dataKey: col.dataKey,
          rowData: item,
          rowIndex: i,
        }),
        value: !(typeParam === "ALL") && parseStringValue(item[col.dataKey]),
        className: col.className,
      };
    });
  });

  // Render
  return (
    <FlexContainer $opacity={isLoaded ? 1 : 0.5}>
      <DataTable
        cols={cols}
        rows={rows}
        sortDir="ASC"
        sortCol={1}
        indexCol={typeParam !== "ALL"}
      />
    </FlexContainer>
  );
}

function StatsLegendsFilterBar() {
  const { FilterBar } = useLegendStats();
  return FilterBar;
}

export default {
  View: StatsLegends,
  FilterBar: StatsLegendsFilterBar,
};

export function meta() {
  return {
    title: [
      "tft:helmet.statistics.legends.title",
      "TFT Legend Statistics – Blitz TFT",
    ],
    description: [
      "tft:helmet.statistics.legends.description",
      "Teamfight Tactics TFT Legend Statistics, Tier, Pickrate, and more.",
    ],
  };
}

function useLegendStats() {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const set = useSetByParam(true);
  const mode = searchParams.get("mode") ?? "ranked";
  // const type = searchParams.get("type") ?? "ALL";
  const View = useMemo(() => {
    return (
      <>
        <Search
          placeholder={t(
            "tft:searchLegendOrAugment",
            "Search Legend or Augment",
          )}
          width={200}
        />
        {!isFutureSet(set) && (
          <>
            <SelectRegion />
            <SelectGameMode />
            {mode !== "normal" && <SelectRank />}
          </>
        )}
      </>
    );
  }, [mode, set, t]);
  return {
    FilterBar: View,
  };
}

const NameContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-2);
  text-align: left;
`;

export function setTotalGames(rows, columns, setTableCol) {
  if (!rows?.length) return null;
  const totalGames = rows.reduce((acc, item) => acc + item?.nb_games, 0);
  if (totalGames > 0) {
    setTableCol(() => {
      const next = clone(columns);
      const pickRateLabel = next.find((i) => i.dataKey === "pick_rate");
      pickRateLabel.labelSub[2].count = formatNumberWithUnit(totalGames);
      return next;
    });
  }
}
